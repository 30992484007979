import React, { useCallback, useState } from "react"
import type { FC } from "react"
import { ErrorBoundary } from "react-error-boundary"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import Page404 from "@/components/nav/page404/page404"
import { PageHeader } from "@/components/nav/page-header/page-header"
import { PageCard } from "@/components/page-card/page-card"
import { Page } from "@/components/page/page"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { ApiQueryName } from "@/models/api"
import { FeatureFlags, useFeature } from "@/services/feature"
import { createQueryKey } from "@/services/utils/createQueryKey"
import { queryClient } from "@/utils/queryClient"
import { useAiChatApi } from "@nlux/react"
import type { ChatItem } from "@nlux/react"

import { Paper, Stack, Typography } from "@mui/material"

import type { AssistantType, Message, Thread } from "../../assistant.types"
import { AssistantChat } from "../../components/assistant-chat"
import { NewThreadButton } from "../../components/new-thread-button"
import { Threads } from "../../components/threads"
import { useThreads } from "../../services/assistant/use-threads"
import type { GetThreadsParams } from "../../services/assistant/use-threads"

const convertMessagesToChatItems = (
  messages: Message[] | undefined
): ChatItem[] | undefined => {
  return messages?.map((message) => ({
    message: message.content,
    role: message.role as ChatItem["role"],
  }))
}

export const AssistantIndexRoute: FC = () => {
  const { isFeatureEnabled } = useFeature()
  const { organization } = useOrganizationContext()

  const assistantApi = useAiChatApi()
  const [activeAssistantType, setActiveAssistantType] =
    useState<AssistantType>("default")
  const [activeThread, setActiveThread] = useState<Thread | null>(null)
  const [initialConversation, setInitialConversation] = useState<
    ChatItem[] | undefined
  >(undefined)

  const { assistantThreadsData, assistantThreadsIsFetched } = useThreads({
    params: {
      organization_id: organization?.id ?? "",
    },
  })

  const handleNewThreadMenuItemClick = useCallback(
    (assistantType: AssistantType) => {
      setActiveAssistantType(assistantType)
      setInitialConversation(undefined)
      setActiveThread(null)
      assistantApi.conversation.reset()
    },
    [assistantApi.conversation]
  )

  const handleThreadClick = useCallback(
    (thread: Thread) => {
      setActiveAssistantType(thread.specialty)
      setInitialConversation(convertMessagesToChatItems(thread.messages))
      setActiveThread(thread)
      assistantApi.conversation.reset()
    },
    [assistantApi.conversation]
  )

  const handleThreadInit = useCallback((thread: Thread) => {
    const threadsParams: GetThreadsParams = {
      organization_id: thread.organizationId,
      specialty: thread.specialty,
    }

    setActiveThread(thread)
    void queryClient.invalidateQueries(
      createQueryKey(
        ApiQueryName.AssistantThreads,
        "getMany",
        null,
        threadsParams
      )
    )
  }, [])

  return isFeatureEnabled(FeatureFlags.ASSISTANT, organization) ? (
    <>
      <PageHeader title="Assistant" />
      <Page fullHeight>
        <ErrorBoundary
          FallbackComponent={ErrorBoundaryFallback}
          onError={logError}
        >
          <PageCard
            sx={{
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Stack
              direction={{ sm: "column", md: "row" }}
              height="100%"
              gap={2}
            >
              <Stack
                flex={{ md: "1 0 350" }}
                gap={2}
                width={{ xs: "100%" }}
                maxWidth={{ md: 350 }}
              >
                <NewThreadButton
                  onMenuItemClick={handleNewThreadMenuItemClick}
                />
                <Paper
                  elevation={0}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.grey[50],
                    flex: 1,
                    px: 1,
                    py: 2,
                  })}
                >
                  <Typography component="h2" variant="h3" ml={2}>
                    Conversations
                  </Typography>
                  <Threads
                    threads={assistantThreadsData}
                    isLoading={!assistantThreadsIsFetched}
                    onThreadClick={handleThreadClick}
                    selectedThread={activeThread}
                  />
                </Paper>
              </Stack>
              <Stack
                alignItems="center"
                flex="2"
                height="100%"
                spacing={2}
                sx={{ mx: { md: "auto" }, maxWidth: { md: 800 } }}
              >
                <div style={{ flex: 1, height: "100%", width: "100%" }}>
                  <AssistantChat
                    api={assistantApi}
                    initialConversation={initialConversation}
                    onThreadInit={handleThreadInit}
                    thread={activeThread}
                    type={activeAssistantType}
                  />
                </div>
              </Stack>
            </Stack>
          </PageCard>
        </ErrorBoundary>
      </Page>
    </>
  ) : (
    <Page404 />
  )
}
