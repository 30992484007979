import React from "react"
import type { FC } from "react"

import nzeroSymbol from "@/assets/images/nzero-symbol.svg"
import { useOrganizationContext } from "@/contexts/organizationProvider"
import { AiChat, AiChatUI, useAsStreamAdapter } from "@nlux/react"
import type { StreamingAdapterObserver } from "@nlux/react"
import "@nlux/themes/unstyled.css"

import { CircularProgress } from "@mui/material"

import { assistants } from "../../assistant.constants"
import type { Assistant } from "../../assistant.types"
import { initThread, sendMessage } from "../../services/assistant"
import "./assistant-chat-theme.css"
import type { AssistantChatProps } from "./assistant-chat.types"

export const AssistantChat: FC<AssistantChatProps> = ({
  api,
  initialConversation,
  onThreadInit,
  thread,
  type,
}) => {
  const { organization } = useOrganizationContext()

  const assistant: Assistant | undefined = assistants.find(
    (a) => a.type === type
  )

  const assistantAdapter = useAsStreamAdapter(
    (message: string, observer: StreamingAdapterObserver): void => {
      if (!initialConversation) {
        void initThread({
          organization_id: organization?.id ?? "",
          specialty: type,
        }).then((response) => {
          onThreadInit(response)
          sendMessage(observer, {
            assistant_id: response.assistantId,
            messages: [
              {
                content: message,
                role: "user",
              },
            ],
            organization_id: organization?.id ?? "",
            specialty: type,
            thread_id: response.threadId,
          })
        })
      } else {
        sendMessage(observer, {
          assistant_id: thread?.assistantId ?? "",
          messages: [
            {
              content: message,
              role: "user",
            },
          ],
          organization_id: organization?.id ?? "",
          specialty: type,
          thread_id: thread?.threadId ?? "",
        })
      }
    },
    [organization, thread, type]
  )

  return (
    <AiChat
      adapter={assistantAdapter}
      api={api}
      composerOptions={{
        placeholder: "How can I help you?",
      }}
      conversationOptions={{
        historyPayloadSize: "max",
      }}
      displayOptions={{
        themeId: "nzero",
      }}
      initialConversation={initialConversation}
      personaOptions={{
        assistant: {
          name: `${assistant?.name} Assistant`,
          avatar: nzeroSymbol as string,
          tagline: assistant?.description,
        },
      }}
    >
      <AiChatUI.Loader>
        <CircularProgress color="primary" size={24} />
      </AiChatUI.Loader>
    </AiChat>
  )
}
