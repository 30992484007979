import React from "react"
import type { FC } from "react"

import {
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material"

import type { ThreadsProps } from "./threads.types"

export const Threads: FC<ThreadsProps> = ({
  threads,
  isLoading,
  onThreadClick,
  selectedThread,
}) => {
  return isLoading || (!isLoading && threads?.length) ? (
    <List>
      {threads?.map((thread) => (
        <ListItemButton
          color="secondary"
          key={thread.threadId}
          onClick={() => {
            if (isLoading) {
              return
            }

            onThreadClick(thread)
          }}
          selected={thread.threadId === selectedThread?.threadId}
          sx={{ borderRadius: 2 }}
        >
          <ListItemText
            disableTypography
            primary={
              !isLoading ? (
                (thread.messages?.[0]?.content ?? "Empty conversation")
              ) : (
                <Skeleton height={16} sx={{ transform: "scale(1)" }} />
              )
            }
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          />
        </ListItemButton>
      ))}
    </List>
  ) : (
    <Typography m={2}>No conversations</Typography>
  )
}
