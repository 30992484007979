import type { Schemas } from "@/services/types"
import { httpClient } from "@/utils/api"
import type { StreamingAdapterObserver } from "@nlux/react"
import type { AxiosResponse } from "axios"
import { SSE } from "sse.js"
import type { ReadyStateEvent, SSEvent } from "sse.js"

import type { Thread } from "../../assistant.types"

export type InitThreadParams = Omit<
  Schemas["ThreadRequest"],
  "assistant_user_id"
>

/**
 * Initializes an assistant thread and returns prior messages
 */
export const initThread = async (params: InitThreadParams): Promise<Thread> => {
  const response: AxiosResponse<Thread> = await httpClient.post(
    "/api/v1/recommendations/assistant/threads",
    params
  )

  return response.data
}

interface SSEMessageText {
  annotations: []
  value: string
}

interface SSEMessageContent {
  index: number
  text: SSEMessageText
  type: string
}

interface SSEMessageDelta {
  content: SSEMessageContent[]
  role: string
}

interface SSEMessageData {
  delta?: SSEMessageDelta
  id: string
  object: string
}

export type SendMessageParams = Omit<
  Schemas["AssistantRequest"],
  "organization_name" | "version"
>

/**
 * Sends a message to the assistant which streams the response using server-sent events
 */
export const sendMessage = (
  observer: StreamingAdapterObserver,
  params: SendMessageParams
): void => {
  const sse = new SSE("/api/v1/recommendations/assistant/conversation", {
    headers: { "Content-Type": "application/json" },
    payload: JSON.stringify(params),
  })

  sse.addEventListener("message", (e: SSEvent) => {
    const data = (
      JSON.parse(JSON.parse(e.data) as string) as { data: SSEMessageData }
    ).data

    if (data.delta?.content.length) {
      observer.next(data.delta.content[0].text.value)
    }
  })

  sse.addEventListener("readystatechange", (e: ReadyStateEvent) => {
    if (e.readyState === sse.CLOSED) {
      sse.close()
      observer.complete()
    }
  })

  sse.addEventListener("error", () => {
    observer.error(
      new Error("I ran into an unexpected error. Please try again.")
    )
  })
}
