import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Route, Routes } from "react-router-dom"

import { ErrorBoundaryFallback } from "@/components/error-boundary/error-boundary-fallback"
import { logError } from "@/components/error-boundary/error-boundary-fallback-utils"
import Page404 from "@/components/nav/page404/page404"
import { SitePath } from "@/models/route"

import Electricity from "./components/electricity/electricity"
import NaturalGas from "./components/naturalGas/naturalGas"
import SiteAnalysis from "./components/siteAnalysis/siteAnalysis"
import { SiteIndexRoute } from "./pages/site-index-route/site-index-route"
import { SitesComplianceRoute } from "./pages/sites-compliance-route/sites-compliance-route"
import { SitesIndexRoute } from "./pages/sites-index-route/sites-index-route"
import { SitesOverviewRoute } from "./pages/sites-overview-route/sites-overview-route"
import { SitesPerformanceRoute } from "./pages/sites-performance-route/sites-performance-route"

/**
 * A component that handles the "/sites/*" route
 *
 * @example
 * return (
 *   <SitesRoute />
 * )
 */
export const SitesRoute = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={logError}>
      <Routes>
        <Route element={<SitesIndexRoute />} path="/">
          <Route element={<SitesOverviewRoute />} path={SitePath.Overview} />
          <Route
            element={<SitesPerformanceRoute />}
            path={SitePath.Performance}
          />
          <Route
            element={<SitesComplianceRoute />}
            path={SitePath.Compliance}
          />
        </Route>

        <Route element={<SiteIndexRoute />} path={`${SitePath.SiteDetails}/`}>
          <Route element={<SiteAnalysis />} path={SitePath.Emissions} />
          <Route element={<Electricity />} path={SitePath.Electricity} />
          <Route element={<NaturalGas />} path={SitePath.NaturalGas} />
        </Route>

        <Route element={<Page404 />} path="*" />
      </Routes>
    </ErrorBoundary>
  )
}
